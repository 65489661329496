<template>
  <div class="flex flex-col min-h-screen">
    <NettbutikkHeaderJuridikaCta />
    <NettbutikkHeader />
    <NettbutikkHeaderBreadcrumbsAndLogout />
    <main class="flex-1">
      <slot />
    </main>
    <Footer />
  </div>
</template>
