<script setup lang="ts">
import type { NavItem } from '~/types/nettbutikk/nav'
import { KsIcon } from '@aschehoug/kloss'

const props = defineProps<{ navItem: NavItem }>()

const badgeRef = useTemplateRef<HTMLDivElement>('badge')

// Make the badge pop when the value changes
watch(() => props.navItem.badge, (newValue, oldValue) => {
  if (oldValue === newValue || !badgeRef.value) {
    return
  }

  badgeRef.value.classList.remove('animate-pop')
  // This looks strange, but we need to trigger a reflow for the animation to work more than once. Calling offsetWidth on an element will do that.
  void badgeRef.value.offsetWidth
  badgeRef.value.classList.add('animate-pop')
})
</script>

<template>
  <NuxtLink
    exact-active-class="active" :to="navItem.href"
    class="md:px-s no-underline flex gap-2xs items-center group relative"
  >
    <KsIcon
      v-if="navItem.icon" :icon="navItem.icon" :scale="1.3"
      class="transition-all"
      :class="{ 'text-blurple-400 -translate-x-3.5': !!navItem.badge }"
    />
    <div
      ref="badge"
      aria-hidden="true" :class="navItem.badge ? 'opacity-1 size-6' : 'opacity-0 size-0'"
      class="absolute transition-all rounded-full bg-blurple-400 flex justify-center items-center text-blurple-50 -top-1"
      v-text="navItem.badge"
    />
    <span
      class="label no-underline text-sm md:text-lg text-center relative before:h-3xs before:rounded-[0.25rem] before:-bottom-2xs before:bg-blurple-200 before:absolute before:[transform:scaleX(0)] group-hover:before:[transform:scaleX(1)] group-[.active]:before:[transform:scaleX(1)]"
      v-text="navItem.label"
    />
  </NuxtLink>
</template>

<style scoped>
.label:before {
  --_width: 60%;

  width: var(--_width);
  left: calc((100% - var(--_width)) / 2);
  transition: transform 0.2s ease;
}
</style>
