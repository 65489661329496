<script setup lang="ts">
import type { NavItem } from '~/types/nettbutikk/nav'
import { faShoppingBag } from '@fortawesome/pro-duotone-svg-icons'

const { loggedIn } = useUserSession()

const products = useProductCatalogue()

const highlightedNavItem = computed(() => {
  const p = products.data.value?.highlighted
  return p
    ? {
        label: getTitle(p) ?? 'Juridika Premium',
        href: p.path,
      }
    : {
        // Sensible default so that the link will work before hydration/the product fetch is complete
        label: 'Juridika Premium',
        href: '/produktkatalog/juridika-premium',
      }
})

const { size } = useCartMetadata()

const navItems = computed<NavItem[]>(() => [
  {
    label: 'Våre abonnement',
    href: '/produktkatalog',
  },
  highlightedNavItem.value,
  {
    label: 'Handlekurv',
    href: '/handlekurv',
    icon: faShoppingBag,
    badge: size.value,
  },
])

const headerRef = useTemplateRef<HTMLElement>('header')

useHeaderHeight(headerRef)
</script>

<template>
  <header ref="header" class="sticky top-0 bg-white z-10">
    <ContentGrid>
      <div class="flex justify-between items-center full-width py-3.5">
        <NuxtLink to="/" aria-label="Hjem">
          <IconsLogoJuridika aria-hidden="true" class="fill-blurple-500" />
        </NuxtLink>
        <nav class="flex-grow mx-l">
          <ul class="flex gap-xs md:gap-s items-center">
            <li v-for="navItem in navItems" :key="navItem.label" class="last-of-type:ml-auto">
              <NettbutikkHeaderNavItem :nav-item="navItem" />
            </li>
          </ul>
        </nav>
        <Button v-if="loggedIn" variant="secondary" size="small" to="/min-side">
          Din&nbsp;konto
        </Button>
        <Button v-else variant="secondary" size="small" href="/login/feide">
          Logg&nbsp;inn
        </Button>
      </div>
    </ContentGrid>
  </header>
</template>
